
// List of common personal email domains
const PERSONAL_EMAIL_DOMAINS: string[] = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "live.com",
    "aol.com",
    "icloud.com",
    "protonmail.com",
    "mail.com",
    "zoho.com"
];

export function getEmailDomain(email: string): string {
    return email.split('@')[1];
}

/**
 * Extract the domain and subdomain from an email address.
 * 
 * @param email The email address to extract the domain from.
 * @returns An object with the domain and subdomain parts of the email address.
 */
export function getEmailDomainWithSubdomain(email: string): { 
    domain: string, 
    subdomain: string | null 
} {
    // Trim input and extract the domain part (support both full emails and bare domains).
    const trimmed = email.trim();
    const domainPart = trimmed.includes('@') ? trimmed.split('@')[1].trim() : trimmed;
    const parts = domainPart.toLowerCase().split('.');

    // If there aren’t enough parts, return the domain as-is.
    if (parts.length < 2) {
        return { domain: domainPart, subdomain: null };
    }

    // List of common multi-part TLDs (all in lowercase).
    const commonMultiPartTLDs = new Set([
        'co.uk', 'org.uk', 'gov.uk', 'ac.uk', 
        'edu.au', 'com.au', 'net.au', 
        'co.in', 'gov.in', 'org.in', 'ac.in', 
        'co.nz', 'gov.nz', 'org.nz',
        'com.br', 'gov.br', 'org.br', 'edu.br', 
        'com.mx', 'gov.mx', 'org.mx',
        'co.za', 'gov.za', 'org.za', 'edu.za', 
        'com.sg', 'gov.sg', 'org.sg',
        'co.jp', 'ac.jp', 
        'co.kr', 'ac.kr', 
        'com.hk', 'org.hk', 'edu.hk'
    ]);

    // Construct the last two labels (e.g. "co.uk" or "com")
    const lastTwoLabels = parts.slice(-2).join('.');
    
    if (commonMultiPartTLDs.has(lastTwoLabels)) {
        // For a known multi-part TLD, the registrable domain is assumed to be 
        // the label immediately preceding the TLD plus the TLD.
        if (parts.length >= 3) {
            // For example: ["briggsequipment", "co", "uk"] becomes "briggsequipment.co.uk"
            const registrableDomain = parts.slice(-3).join('.');
            const subdomain = parts.length > 3 ? parts.slice(0, -3).join('.') : null;
            return { domain: registrableDomain, subdomain: subdomain };
        } else {
            // Fallback if we don't have an extra label.
            return { domain: domainPart, subdomain: null };
        }
    } else {
        // Default: For a "normal" TLD (e.g. example.com)
        const registrableDomain = parts.slice(-2).join('.');
        const subdomain = parts.length > 2 ? parts.slice(0, -2).join('.') : null;
        return { domain: registrableDomain, subdomain: subdomain };
    }
}

export function isWorkEmail(email: string): boolean {
    // Extract the domain from the email address
    const emailDomain = getEmailDomain(email);

    // Check if the domain is in the list of personal email domains
    if (!emailDomain) {
        return false; // Invalid email format
    }

    return !PERSONAL_EMAIL_DOMAINS.includes(emailDomain.toLowerCase());
}
